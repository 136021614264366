import { useContext } from "react";
import useSWR from "swr";
import { Menu, Seo, Status } from "~components";
import { EmptyState } from "~components/events/events.EmptyState";
import { BaseContext } from "~context";
import { Detail } from "~features/events/components/EventDetails/EventDetails";
import { sdk } from "~lib/graphQL";
import { Event } from "~graphql/sdk";
import { MenuEventHeader } from "~components/common/Menu/menu.EventHeader";

const SingleEventPage = () => {
  const { organization } = useContext(BaseContext);

  const { data, error } = useSWR(
    organization?.id ? ["singleEvent", organization?.id] : null,
    async (_: string, orgId: string) => sdk({ orgId }).singleEvent()
  );

  return (
    <>
      <Seo title="Event page" description="Event overview page for" />
      <Menu>
        {data?.currentOrganization?.singleEvent && (
          <MenuEventHeader
            event={data?.currentOrganization?.singleEvent as Event}
          />
        )}
      </Menu>
      <Status loading={!data && !error} error={error}>
        {data?.currentOrganization?.singleEvent ? (
          <Detail
            // @ts-expect-error type mismatch
            event={data?.currentOrganization?.singleEvent}
          />
        ) : (
          <EmptyState />
        )}
      </Status>
    </>
  );
};

export default SingleEventPage;
